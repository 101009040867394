import { useNavigate } from "react-router-dom";
import { Element } from "../../utils/models/config.model";

export default function CardImageCenterTitleAndDescCenter({media_base_url,  element, currentStepValue, setCurrentStepValue, /* setCurrentStepProductTitle */ }:
  { media_base_url: string, element: Element, currentStepValue: string | null, setCurrentStepValue: (stepValue: string, stepTitle: string, product?: string) => void, /* setCurrentStepProductTitle: (stepValue: string) => void */ }) {
  const navigate = useNavigate()
  const appendOption = () => {
    if (element.id !== currentStepValue) {
      navigate(element.id)
    }
  }
  return (
    <div
      key={element.id}
      className={`m-c-card-1${currentStepValue === element.id ? ' m-c-card-1-selected' : ''} flex flex-col justify-center items-center p-6 md:p-16`}
      onClick={() => {
        setCurrentStepValue(element.id, element.title)
      }}>
      {element.image && (
        <img src={media_base_url + element.image} />
      )}
      <h2 className="m-c-b-21 mt-5 uppercase">{element.title}</h2>
      <h3 className="m-c-b-21">{element.subtitle}</h3>
      {element.description && (
        <p className="m-c-r-14 mt-2">{element.description}</p>
      )}
    </div>
  )
}