import { useEffect, useRef, useState } from "react";
import { useNavigatorUtil } from "../hooks/useNavigatorUtil";
import {
  Step,
  StepConfig,
  StepConfigDefault,
} from "../utils/models/config.model";
import { schedaProdotto } from "../services/events";

export default function TopNavigator({
  steps,
  currentStepSetup,
  currentStepValue,
  setCurrentStepKey,
  handleVisibilityChange,
  eventDispatcher,
}: {
  steps: Step[];
  currentStepSetup: StepConfig;
  currentStepValue: string | null;
  setCurrentStepKey: (stepKey: string) => void;
  handleVisibilityChange?: (visibility: boolean) => void;
  eventDispatcher: typeof dispatchEvent;
}) {
  const titleReference = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(true);
  const { isFirst, back, next, title } = useNavigatorUtil(
    steps,
    currentStepSetup,
    setCurrentStepKey
  );
  const [headerMargin, setHeaderMargin] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      //FIXME: questo sistema è valido solo su ecommerce e va reso general purpose
      const bottomHeaderPoint =
        document.getElementsByTagName("header")[0]?.getBoundingClientRect()
          ?.bottom || 0; //0 perchè mi serbe in locale nel caso in cui non ho il fake header nell'html
      setHeaderMargin(bottomHeaderPoint);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const rootMargin = headerMargin * -1 + "px 0px 0px 0px";
    const io = new IntersectionObserver(
      (el) => {
        const change = el[0];
        setIsVisible(change.isIntersecting);
      },
      {
        root: titleReference.current?.ownerDocument,
        rootMargin,
        threshold: 0.5,
      }
    );
    io.observe(titleReference.current!);
    return () => io.disconnect();
  }, [headerMargin]);
  useEffect(() => {
    if (typeof handleVisibilityChange === "function") {
      handleVisibilityChange(isVisible);
    }
  }, [isVisible]);

  const notifyClickSchedaProdotto = () => {
    schedaProdotto(
      {
        item_id: currentStepSetup.stepExtra.linkInfos.sku,
        item_name: currentStepSetup.stepExtra.linkInfos.item_name,
        price: currentStepSetup.stepExtra.linkInfos.price,
        discount: currentStepSetup.stepExtra.linkInfos?.discount,
        affiliation: "store online",
        currency: "EUR",
        index: 0,
        item_category: "cucine",
        quantity: 1,
      },
      eventDispatcher
    );
  };
  return (
    <>
      <div
        className={`md:hidden md:mt-8 ${currentStepSetup.id !== "end" ? "mb-8" : ""
          } justify-center`}
      >
        <div className="flex flex-col justify-center">
          <h1
            className={
              currentStepSetup.id === "end"
                ? "m-c-b-31 m-c-tc-42AA43"
                : "m-c-m-25 m-c-tc-333333"
            }
            dangerouslySetInnerHTML={{ __html: title }}
          ></h1>
          {(currentStepSetup as StepConfigDefault)?.elements.length === 0 && (
            <h1 className="m-c-r-16 m-c-tc-333333">
              {currentStepSetup?.productTitle}
            </h1>
          )}
          {currentStepSetup.id === "end" && (
            <div className="mb-8 mt-3 md:block hidden">
              <a
                href={currentStepSetup.stepExtra.linkInfos.url}
                onClick={() => notifyClickSchedaProdotto()}
                target="_blank"
              >
                <button className="m-c-button-variant m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF !w-full !px-0 no-box-shadow">
                  <div className="m-c-content-wrapper">
                    Vedi scheda prodotto
                  </div>
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        ref={titleReference}
        className={`hidden md:grid md:grid-cols-12`}
        style={{ marginBottom: 50 }}
      >
        <div
          className={`col-span-4 lg:col-span-4 xl:col-span-3 flex flex-col items-start justify-center`}
        >
          {!isFirst && (
            <button
              className="m-c-button-variant m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF"
              onClick={() => {
                back();
              }}
            >
              <div className="m-c-content-wrapper">
                <div
                  className="m-c-button-triangle-left"
                  style={{ marginRight: 9.13 }}
                ></div>
                Indietro
              </div>
            </button>
          )}
        </div>
        <div
          className={`col-span-4 lg:col-span-4 xl:col-span-6 flex flex-col items-center justify-center`}
        >
          <div className="hidden md:flex md:flex-col justify-center">
            <h1
              className={
                currentStepSetup.id === "end"
                  ? "m-c-b-31 m-c-tc-42AA43 end-title"
                  : "m-c-m-25 m-c-tc-333333"
              }
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            {(currentStepSetup as StepConfigDefault)?.elements.length === 0 && (
              <h1 className="m-c-r-16 m-c-tc-333333">
                {currentStepSetup?.productTitle}
              </h1>
            )}
          </div>
        </div>
        <div
          className="col-span-4
          lg:col-span-4
          xl:col-span-3
          flex flex-col items-end justify-center"
        >
          {currentStepSetup.id !== "end" && (
            <button
              className={`m-c-button${currentStepSetup?.required && !currentStepValue
                ? "-disabled"
                : ""
                } m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF `}
              disabled={currentStepSetup?.required && !currentStepValue}
              onClick={() => {
                next();
              }}
            >
              <div className="m-c-content-wrapper">
                Avanti
                <div
                  className="m-c-button-triangle-right"
                  style={{ marginLeft: 9.13 }}
                ></div>
              </div>
            </button>
          )}
          {currentStepSetup.id === "end" && (
            <a
              href={currentStepSetup.stepExtra.linkInfos.url}
              onClick={() => notifyClickSchedaProdotto()}
              target="_blank"
              className="!w-full"
            >
              <button className="m-c-button-variant m-c-rounded-button !rounded-full m-c-b-16 m-c-tc-FFFFFF md:px-4 lg:px-0 !w-full no-box-shadow">
                <div className="m-c-content-wrapper">Vedi scheda prodotto</div>
              </button>
            </a>
          )}
        </div>
      </div>
    </>
  );
}
