import css from "!!raw-loader!./combined.css"; /* Output del ti tailwind combinato con il mio stile */
import { QueryClientProvider } from "@tanstack/react-query";
import { FC, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppContext } from "./AppContext";
import Inner from "./components/Inner";
import { WebComponentProps, queryClient } from "./services/config";

const App: FC<WebComponentProps & { container: ShadowRoot }> = ({
  startstep,
  language,
  channel,
  category,
  container,
  api_base_url,
  media_base_url,
  userData,
}: WebComponentProps & { container: ShadowRoot }) => {
  const eventDispatcher = container.host.dispatchEvent.bind(container.host);
  if (!language) {
    return (
      <>
        <style>{css}</style>
        <div className="m-c-b-21 m-c-tc-333333">
          Please provide the language to web-mondo-convenienza tag.
        </div>
      </>
    );
  }
  if (!channel) {
    return (
      <>
        <style>{css}</style>
        <div className="m-c-b-21 m-c-tc-333333">
          Please provide the channel to web-mondo-convenienza tag.
        </div>
      </>
    );
  }
  if (!startstep) {
    return (
      <>
        <style>{css}</style>
        <div className="m-c-b-21 m-c-tc-333333">
          Please provide the startstep to web-mondo-convenienza tag.
        </div>
      </>
    );
  }
  if (!category) {
    return (
      <>
        <style>{css}</style>
        <div className="m-c-b-21 m-c-tc-333333">
          Please provide the category to web-mondo-convenienza tag.
        </div>
      </>
    );
  }
  if (!api_base_url) {
    return (
      <>
        <style>{css}</style>
        <div className="m-c-b-21 m-c-tc-333333">
          Please provide the api_base_url to web-mondo-convenienza tag.
        </div>
      </>
    );
  }
  if (!media_base_url) {
    return (
      <>
        <style>{css}</style>
        <div className="m-c-b-21 m-c-tc-333333">
          Please provide the media_base_url to web-mondo-convenienza tag.
        </div>
      </>
    );
  }
  const appContext = useMemo(
    () => ({
      startstep,
      language,
      channel,
      category,
      api_base_url,
      media_base_url,
      userData,
      eventDispatcher,
    }),
    [
      startstep,
      language,
      channel,
      category,
      api_base_url,
      media_base_url,
      eventDispatcher,
      ...Object.values(userData || {})
    ]
  );
  return (
    /*  <ThemeProvider theme={themeMode}> */
    <>
      <style>{css}</style>
      <div className="m-c-wrapper p-0 md:p-8" id="app-wrapper">
        <AppContext.Provider value={appContext}>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Inner
                category={category}
                startstep={startstep}
                language={language}
                channel={channel}
                eventDispatcher={eventDispatcher}
                api_base_url={api_base_url}
                media_base_url={media_base_url}
              ></Inner>
            </QueryClientProvider>
          </BrowserRouter>
        </AppContext.Provider>
      </div>
    </>
    /* </ThemeProvider> */
  );
};
function ErrorFallback() {
  return <>Qualcosa è andato storto</>;
}

export default App;
