import { QueryClient } from '@tanstack/react-query';
import { Configurazione, Step } from "../utils/models/config.model";
export type WebComponentProps = {
    startstep: SetupReqBody['step']
    channel: SetupReqBody['channel']
    language: SetupReqBody['language']
    category: string,
    api_base_url: string,
    media_base_url: string,
    userData?: any
}
export type WebComponentInnerProps = {
    startstep: SetupReqBody['step']
    channel: SetupReqBody['channel']
    language: SetupReqBody['language']
    category: string,
    api_base_url: string,
    media_base_url: string,
    eventDispatcher: typeof dispatchEvent
}
type SetupReqBody = {
    step: string,
    selected: {},
    channel: "ecommerce" | "mobile",
    language: string,
    products: {
        [key: string]: any
    }
}
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // refetchOnMount: false,
            refetchOnWindowFocus: false,
        }
    }
});
export type HTTPError = {
    httpStatus: number,
}
export async function getConfiguration({ body, api_base_url, category }: { body: SetupReqBody, api_base_url: WebComponentProps['api_base_url'],category: WebComponentProps['category'] }) {
    let setup = await fetchJson<Configurazione | HTTPError>(`${api_base_url}${category}`, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    });
    if('stepConfig' in setup) {
        if(typeof setup.stepConfig.cols === 'number') {
            setup.stepConfig.cols = {
                xs: 2,
                md: 3,
                xl: setup.stepConfig.cols
            };
        } else {
            setup.stepConfig.cols = {
                xs: 2,
                md: 3,
                xl: 4,
                ...setup.stepConfig.cols,
            };
        }
        if('stepExtra' in setup) {
            setup.stepConfig.stepExtra = setup.stepExtra;
        }
    }
    return setup
}

export const fetchJson = <T>(input: any, init?: RequestInit | undefined): Promise<T> => fetch(input, init)
    .then(
        async response => {
            try {
                if (!response.ok) {
                }
                const responseBody = await response.json();
                return responseBody as T
            } catch (e) {
                console.log(e)
                throw e;
            }
        }
    );

type StepBuilderResType = {
    selected: {
        [key: string]: string
    },
    products: {
        [key: string]: string
    },
}
export function stepBuilder(currentStepKey: string, steps: Step[], params: URLSearchParams): StepBuilderResType {
    let selected = {}
    let products = {}
    if (steps.length === 0) {
        const stepName = currentStepKey;
        if (params.get(stepName)) {
            selected = {
                ...selected,
                [`${stepName}`]: params.get(stepName)
            }
        }
        if (params.get(`${stepName}_products`) !== null) {
            products = {
                ...products,
                [`${stepName.replace('_products', '')}`]: params.get(`${stepName}_products`)
            }
        }
    } else {
        for (let index = 0; index < steps.length; index++) {
            const element = steps[index];
            const stepName = element.id
            if (stepName !== currentStepKey) {
                if (params.get(stepName)) {
                    selected = {
                        ...selected,
                        [`${stepName}`]: params.get(stepName)
                    }
                }
                if (params.get(`${stepName}_products`) !== null) {
                    products = {
                        ...products,
                        [`${stepName.replace('_products', '')}`]: params.get(`${stepName}_products`)
                    }
                }
            }
        }
    }

    return {
        selected,
        products
    }
}