import { Link, useSearchParams } from "react-router-dom";
import { Configurazione, Step } from "../utils/models/config.model";
import { useContext, useEffect, useRef } from "react";
import { raiseEvent, standardEventObj } from "../services/eventsV2";
import { AppContext } from "../AppContext";
export default function Header({
    steps,
    currentStep,
}: {
    steps: Step[];
    currentStep: Configurazione["stepConfig"];
}) {
    const { eventDispatcher } = useContext(AppContext)!;
    const [searchParams] = useSearchParams();
    const ref = useRef<HTMLDivElement>(null);
    let currentStepIndex = 0;
    for (let i = 0; i < steps.length; i++) {
        if (steps[i].id === currentStep.id) {
            currentStepIndex = i;
        }
    }
    useEffect(() => {
        ref.current?.scrollIntoView({ inline: "center", block: "center" });
    }, [currentStep.id]);

    return (
        <>
            <div className="m-c-b-35 m-c-tc-333333 m-c-t-uppercase !text-start px-8 pt-4 md:!p-0">
                cucine composizioni fisse
            </div>
            <div className="relative" id="m-c-nav-bar-slider">
                <div className="m-c-nav-bar-container">
                    {steps.map((el, index) => {
                        const localSearchPrams = new URLSearchParams(
                            searchParams?.toString()
                        );
                        localSearchPrams.set("current_step", el.id);
                        return (
                            <div className="m-c-link-wrapper" key={el.id}>
                                {currentStepIndex > index && (
                                    <Link
                                        className="m-c-active-bottom-transparent flex-col"
                                        to={{
                                            search: localSearchPrams?.toString(),
                                        }}
                                        onClick={() => {
                                            raiseEvent(
                                                {
                                                    ...standardEventObj,
                                                    action: "navigation",
                                                    action_type: "navbar",
                                                    label: currentStep.id,
                                                },
                                                eventDispatcher
                                            );
                                        }}
                                    >
                                        <div className="m-c-sb-18-header m-c-tc-3E3C3C !text-start whitespace-nowrap">
                                            {el.label}
                                        </div>
                                        <div className="m-c-r-12 m-c-tc-333333 !text-start whitespace-nowrap mt-[2px] md:mt-[4px]">
                                            {searchParams.get(
                                                `${el.id}_title`
                                            ) === null && "Nessuna Selezione"}
                                            {searchParams.get(
                                                `${el.id}_title`
                                            ) !== null &&
                                                ((
                                                    searchParams.get(
                                                        `${el.id}_title`
                                                    ) as string
                                                )?.length > 20
                                                    ? (
                                                          searchParams.get(
                                                              `${el.id}_title`
                                                          ) as string
                                                      ).slice(0, 20) + "..."
                                                    : searchParams.get(
                                                          `${el.id}_title`
                                                      ))}
                                        </div>
                                    </Link>
                                )}
                                {index === currentStepIndex && (
                                    <>
                                        <div
                                            ref={ref}
                                            className="m-c-sb-18-header m-c-tc-3E3C3C m-c-active-bottom-line whitespace-nowrap"
                                            style={{
                                                marginBottom:
                                                    "4px solid #DB230B",
                                            }}
                                        >
                                            {el.label}
                                        </div>
                                    </>
                                )}

                                {currentStepIndex < index && (
                                    <button
                                        disabled
                                        className="m-c-r-18-header m-c-tc-333333 !text-start flex-col cursor-pointer"
                                        style={{ padding: 0 }}
                                    >
                                        {el.label}
                                    </button>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
