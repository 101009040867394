import { useEffect, useRef, useState } from "react";
import { useNavigatorUtil } from "../hooks/useNavigatorUtil";
import { Step, StepConfig } from "../utils/models/config.model";
import { schedaProdotto } from "../services/events";

export default function BottomNavigator({
  steps,
  currentStepSetup,
  currentStepValue,
  setCurrentStepKey,
  handleVisibilityChange,
  eventDispatcher
}: {
  steps: Step[];
  currentStepSetup: StepConfig;
  currentStepValue: string | null;
  setCurrentStepKey: (stepKey: string) => void;
  handleVisibilityChange: (value: boolean) => void;
  eventDispatcher: typeof dispatchEvent;

}) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { isFirst, back, next } = useNavigatorUtil(
    steps,
    currentStepSetup,
    setCurrentStepKey
  );
  useEffect(() => {
    const io = new IntersectionObserver(
      (el) => {
        const change = el[0];
        setIsVisible(change.isIntersecting);
      },
      {
        // root: titleReference.current?.ownerDocument,
        threshold: 0.5,
      }
    );
    io.observe(ref.current!);
    return () => io.disconnect();
  }, []);
  useEffect(() => {
    handleVisibilityChange(isVisible);
  }, [handleVisibilityChange, isVisible]);
  const notifyClickSchedaProdotto = () => {
    schedaProdotto(
      {
        item_id: currentStepSetup.stepExtra.sid[0], // FIXME, quale sid?
        price: currentStepSetup.stepExtra.total, // FIXME quale è il prezzo?
        affiliation: "store online",
        currency: "EUR",
        index: 0,
        item_category: "cucine",
        quantity: 1,
      },
      eventDispatcher
    );
  };
  return (
    <>
      <div ref={ref} style={{ marginBottom: 50 }}>
        {currentStepSetup.id !== "end" && (
          <div
            className={`flex ${isFirst ? "justify-end" : "justify-between"
              } pt-8`}
          >
            <div className={``}>
              {!isFirst && (
                <button
                  className="m-c-button-variant m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF"
                  onClick={() => {
                    back();
                  }}
                >
                  <div className="m-c-content-wrapper">
                    <div
                      className="m-c-button-triangle-left"
                      style={{ marginRight: 9.13 }}
                    ></div>
                    Indietro
                  </div>
                </button>
              )}
            </div>
            <div className={``}>
              <button
                className={`m-c-button${currentStepSetup?.required && !currentStepValue
                  ? "-disabled"
                  : ""
                  } m-c-rounded-button m-c-b-16 m-c-tc-FFFFFF`}
                disabled={currentStepSetup?.required && !currentStepValue}
                onClick={() => {
                  next();
                }}
              >
                <div className="m-c-content-wrapper">
                  Avanti
                  <div
                    className="m-c-button-triangle-right"
                    style={{ marginLeft: 9.13 }}
                  ></div>
                </div>
              </button>
            </div>
          </div>
        )}
        {currentStepSetup.id === "end" && (
          <div className="md:hidden mt-4">
            <div className={`flex justify-${currentStepSetup.stepExtra.linkInfos.url ? 'between' : 'start'} m-c-b-21 m-c-tc-1C2F66`}>
              <div
                onClick={() => {
                  back();
                }}
                className="flex items-center cursor-pointer"
              >
                <div
                  className="m-c-button-triangle-left"
                  style={{ marginRight: 9.13 }}
                ></div>
                <div className="underline">Indietro</div>
              </div>
              {currentStepSetup.stepExtra.linkInfos.url && (

                <a
                  href={currentStepSetup.stepExtra.linkInfos.url}
                  onClick={() => notifyClickSchedaProdotto()}
                  target="_blank"
                  className="flex items-center cursor-pointer"
                >
                  <div className="underline">Vai alla scheda prodotto</div>
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
