import { Configurazione, Step, StepConfigDefault, StepExtraEnd } from "../utils/models/config.model";
import DefaultStep from "./DefaultStep";
import End from "./End";


export default function SingleStep({ steps, media_base_url, stepConfig, stepExtra, currentStepValue, currentStepProduct, setCurrentStepValue, setCurrentStepKey, eventDispatcher /* setCurrentStepProductTitle */ }: {
  steps: Step[];
  media_base_url: string
  stepConfig: Configurazione['stepConfig'],
  stepExtra?: StepExtraEnd,
  currentStepValue: string | null
  currentStepProduct: string | null
  setCurrentStepValue: (stepValue: string, stepTitle: string, product?: string) => void,
  setCurrentStepKey: (stepKey: string) => void;
  eventDispatcher: typeof dispatchEvent
}) {
  switch (stepConfig.id) {
    case 'end':
      return <End media_base_url={media_base_url} endConfig={stepExtra as StepExtraEnd} eventDispatcher={eventDispatcher}></End>;
    default:
      return (
        <DefaultStep
          steps={steps}
          media_base_url={media_base_url}
          currentStepValue={currentStepValue}
          currentStepProduct={currentStepProduct}
          setCurrentStepValue={setCurrentStepValue}
          setCurrentStepKey={setCurrentStepKey}
          step={stepConfig as StepConfigDefault}
          key={stepConfig.id}
          eventDispatcher={eventDispatcher}
        />
      )
  }
}