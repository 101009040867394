export function htmlDecode(input: string) {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}
export function fixToPrice(price: number) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '€'
}
export function fixToPriceWithoutEuroSimbol(price: number) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}