import { useEffect } from "react"
import { ctaInteraction } from "../../services/events"
/**
 * 
 * @param html html che viene inglobato nella modale
 * @param onClose handler di chiusura della modale
 * @param labelCta è la stringa associata alla cta che ha permesso l'apertura della modale
 * @returns JSX.Element
 */
export default function ProductInfoModal({ html, onClose, labelCta, eventDispatcher }: { html: string, onClose: () => void, labelCta: string, eventDispatcher: typeof dispatchEvent }) {
  useEffect(() => {
    ctaInteraction({
      action: "cta_global",
      category: "click",
      event: "click_cta",
      label: labelCta
    }, eventDispatcher)
  }, [])
  return (
    <div className="mondo-convenienza-product-modal-overlay fixed top-[40vh]">
      <div className="mondo-convenienza-product-modal-vertically-centered">
        <div className="mondo-convenienza-product-modal p-16 pr-10">
          <div className="hidden md:block absolute top-6 right-6" style={{ zIndex: 1000 }}>
            <button onClick={onClose} className="p-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="#333333" height="2em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
            </button>
          </div>
          <div className="md:hidden mobile-close-button-container" style={{ zIndex: 1000 }}>
            <div className="">
              <button onClick={onClose} className="p-0">
                <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
              </button>
            </div>
          </div>
          <div className="mondo-convenienza-product-modal-content pr-4">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </div>
    </div>
  )
}