import r2wc from "@r2wc/react-to-web-component"

import App from "./App"

const WebComponent = r2wc(App, {
  shadow: "open",
  props: {
    language: "string",
    channel: "string",
    startstep: "string",
    category: "string",
    api_base_url: "string",
    media_base_url: "string",
    onComplete: "function",
    userData: "json"
  },
});

customElements.define("web-mondo-convenienza", WebComponent)
