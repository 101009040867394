type TrackingBaseEvent = {
    event: "kitchen_configurator";
    category: "kitchen_configurator";
    action: "start" | "end" | "navigation" | string;
    start_point: "menu" | "product_page";
    label?: "summary" | string;
    category_name: "cucine";
    product_name: string | null;
    product_id: string | null;
    action_type?: "avanti" | "indietro" | "navbar" | never;
};

type NavigationEvent = TrackingBaseEvent & {
    action: "navigation";
    label: string;
    action_type: "avanti" | "indietro" | "navbar";
};

type StartEvent = TrackingBaseEvent & {
    action: "start";
    label?: never;
    action_type?: never;
};
type EndEvent = TrackingBaseEvent & {
    action: "end";
    label: "summary";
    action_type?: never;
};
type SelectionEvent = TrackingBaseEvent & {
    action: string;
    label: string;
    action_type?: never;
};

export type TrackingEvent =
    | NavigationEvent
    | SelectionEvent
    | StartEvent
    | EndEvent;

export const standardEventObj: Pick<
    TrackingEvent,
    "category" | "start_point" | "product_id" | "product_name" | "category_name"
> = {
    category: `kitchen_configurator`, //Todo: Da aggiungere alle props del componente
    start_point: "menu", //Todo: Non so se sia corretto
    category_name: "cucine", //Todo: Da aggiungere alle props del componente
    product_name: null, //Todo: Da aggiungere alle props del componente
    product_id: null, //Todo: Da aggiungere alle props del componente
};

export function raiseEvent<T extends TrackingEvent = TrackingEvent>(
    event: Omit<T, "event" | "category">,
    eventDispatcher: typeof dispatchEvent
) {
    const trackingEvent = new CustomEvent<TrackingEvent>(
        "decision-tree-tag-manager",
        {
            detail: {
                event: "kitchen_configurator",
                category: "kitchen_configurator",
                ...(Object.fromEntries(
                    Object.entries(event).filter(([_, v]) => v != null)
                ) as typeof event),
            } as TrackingEvent,
            bubbles: true,
            cancelable: true,
            composed: false,
        }
    );
    eventDispatcher(trackingEvent);
}

export const raiseNavigationEvent = (
    event: Omit<NavigationEvent, "event" | "category">,
    eventDispatcher: typeof dispatchEvent
) => raiseEvent(event, eventDispatcher);
export const raiseSelectionEvent = (
    event: Omit<SelectionEvent, "event" | "category">,
    eventDispatcher: typeof dispatchEvent
) =>
    raiseEvent(
        {
            ...event,
        },
        eventDispatcher
    );
