import { Element, Step } from "../../utils/models/config.model";

export default function Option({ index, media_base_url, element, currentStepValue, steps, step, setCurrentStepValue, eventDispatcher }:
  { index: number, media_base_url: string, element: Element, currentStepValue: string | null, steps: Step[], step: Step, setCurrentStepValue: (stepValue: string, stepTitle: string, product?: string) => void, eventDispatcher: typeof dispatchEvent }) {
  let asterisks = ""
  for (let i = 0; i <= index; i++) {
    asterisks = asterisks + "*";
  }
  /* 
   action: "cta_global",
      category: "kitchen_configurator",
      event: `kitchen_configutator_s${indexOfCurrentStep + 1}_${currentStepKey}`,
  */

  return (
    <>
      <div
        className={`m-c-card-option-1${currentStepValue === element.id ? ' m-c-card-option-1-selected' : ''} flex justify-center items-center p-6 md:p-[26px]`}
        style={{ height: "fit-content !important" }}
        onClick={() => {
          // const event: OptionSelectionEvent = {
          //   event: `kitchen_configutator_s${steps.findIndex((el) => el.id === step.id) + 1}_${index+1}_${step.id}`,
          //   category: 'kitchen_configurator',
          //   action: `s${steps.findIndex((el) => el.id === step.id) + 1}_${index+1}_${step.id}`,
          //   start_point: 'menu',
          //   label: element.title,
          //   product_name: null, //Todo: Da aggiungere alle props del componente
          //   category_name: "cucine",
          //   product_id: null, //Todo: Da aggiungere alle props del componente
          // }
          /// SoptionSelectedEvent(event, eventDispatcher)
          setCurrentStepValue(element.id, element.title)
        }}>
        <img className="option-image" src={media_base_url + element.image} />
        <div className="m-c-b-21 m-c-b-option-title m-c-tc-333333 uppercase ml-4 !text-start">{element.title}{asterisks}</div>
      </div>
    </>
  )
}
