import { useCallback, useEffect, useRef, useState } from "react";
import { Configurazione, Element, ResponsiveCols, Step, StepConfigDefault } from "../utils/models/config.model";
import CardImageCenterTitleAndDescCenter from "./Shared/CardImageCenterTitleAndDescCenter";
import CustomProduct from "./Shared/CustomProduct";
import ImageTopTitleAndSubtitleLeft from "./Shared/ImageTopTitleAndSubtitleLeft";
import ImageTopTitleAndSubtitleLeftAndDescriptionOrPriceRight from "./Shared/ImageTopTitleAndSubtitleLeftAndDescriptionOrPriceRight";
import ImageTopTitleSxLeftAndDescriptionOrPriceRight from "./Shared/ImageTopTitleSxLeftAndDescriptionOrPriceRight";
import ImageWithTextRight from "./Shared/ImageWithTextRight";
import Option from "./Shared/Option";
import { useNavigatorUtil } from "../hooks/useNavigatorUtil";

export default function DefaultStep({
    steps, media_base_url, eventDispatcher, step, currentStepValue, currentStepProduct, setCurrentStepValue, setCurrentStepKey
}: {
    steps: Step[];

    media_base_url: string,
    step: Configurazione['stepConfig'],
    currentStepValue: string | null
    currentStepProduct: string | null
    setCurrentStepValue: (stepValue: string, stepTitle: string, product?: string) => void,
    setCurrentStepKey: (stepKey: string) => void;
    eventDispatcher: typeof dispatchEvent
}) {
    const ref = useRef<HTMLDivElement>(null);
    const xlCols = ((step as StepConfigDefault).cols as ResponsiveCols)?.xl
    const mdCols = ((step as StepConfigDefault).cols as ResponsiveCols)?.md
    const xsCols = ((step as StepConfigDefault).cols as ResponsiveCols)?.xs
    const productSelected = !!currentStepProduct;
    const productsEnabled = ((step as StepConfigDefault).elements.length === 0 && (step as StepConfigDefault).products) || (step as StepConfigDefault).elements.find((el) => el.id === currentStepValue)?.enableProducts;
    const setProductSelected = useCallback((sid: string | null, title?: string) => {
        if (!sid) {
            setCurrentStepValue(currentStepValue || "", "", sid || undefined);
        } else {
            setCurrentStepValue(currentStepValue || "", title || "", sid);
        }
    }, [currentStepValue, setCurrentStepValue, step]);
    useEffect(() => {
        if (productsEnabled && !productSelected) {
            ref.current?.scrollIntoView({
                behavior: 'smooth'
            })
        } else if (!productsEnabled && productSelected) {
            setProductSelected(null)
        }
    }, [productsEnabled, productSelected])

    return (
        <>
            {/* Caso semplice in cui devo ciclare gli elementi */}
            {!((step as StepConfigDefault).elements.length > 0 && (step as StepConfigDefault).products.length > 0) && (
                <div className={`grid grid-cols-${xsCols} sm:grid-cols-${xsCols} md:grid-cols-${mdCols} xl:grid-cols-${xlCols} gap-[25px] xl:gap-[33px]`}>
                    {(step as StepConfigDefault).elements.map((element, index) => (
                        <SingleElement
                            index={index}
                            media_base_url={media_base_url}
                            key={element.id}
                            template={(step as StepConfigDefault).template}
                            element={element}
                            steps={steps}
                            step={step}
                            currentStepValue={currentStepValue}
                            setCurrentStepValue={setCurrentStepValue}
                            eventDispatcher={eventDispatcher}
                        />
                    ))}
                </div>
            )}
            {((step as StepConfigDefault).elements.length === 0 && (step as StepConfigDefault).products.length === 0) && (
                <div className="m-c-sb-21 m-c-tc-464545 text-center">OPS... Non ci sono opzioni disponibili.</div>
            )}
            {/* Caso speciale in cui devo ciclare gli elementi come se fossero opzioni */}
            {/* Nel caso in ho sia elementi che prodotti il comportamento è diverso.. Gli elementi in realtà sono delle opzioni che 
    permettono di sloccare la visualizzazione dei prodotto. Posso avere un elemento che si chiama "Predisposta alla lavastoviglie" */}
            {(step as StepConfigDefault).elements.length > 0 && (step as StepConfigDefault).products.length > 0 && (

                <div className={`grid grid-cols-1 md:grid-cols-2 gap-[25px] xl:gap-[33px]`}>
                    {(step as StepConfigDefault).elements.map((element, index) => (
                        <SingleElement
                            index={index}
                            media_base_url={media_base_url}
                            key={element.id}
                            template="option"
                            element={element}
                            currentStepValue={currentStepValue}
                            setCurrentStepValue={setCurrentStepValue}
                            steps={steps}
                            step={step}
                            eventDispatcher={eventDispatcher}
                        />
                    ))}
                </div>
            )}
            {(step as StepConfigDefault).template === 'image-with-text-right' && (
                <div className="md:flex md:flex-col text-start mt-10 md:mt-16">
                    {(step as StepConfigDefault).template === 'image-with-text-right' && (step as StepConfigDefault).elements.map((el, index) => (
                        <div key={el.id} className="m-c-l-16 m-c-tc-464545 mb-[26px] !text-start">
                            <b style={{ fontWeight: "bold" }}>{[...Array(index + 1)].map((fakeElement) => {
                                return "*"
                            })}{el.title}:</b>&nbsp;{el.subtitle}</div>
                    )
                    )}
                </div>
            )}
            {productsEnabled && (step as StepConfigDefault)?.elements.length !== 0 && (
                <div ref={ref} className="mt-14 mb-7">
                    <h1 className="m-c-m-25 m-c-tc-333333">{step?.productTitle}</h1>
                </div>
            )}
            <div className={`grid grid-cols-${xsCols} md:grid-cols-${mdCols} xl:grid-cols-${xlCols || 3} gap-[25px] xl:gap-[33px]`} id="products-list">
                {/* Per mostrare i prodotti devo avere solamente quelli e non avere degli elementi.
            Nel caso in cui ho entrambi allora mostro i prodotti solo nel caso in cui l'elemento selezionato ha la proprietà enableProducts === true*/}
                {productsEnabled &&
                    (step as StepConfigDefault).products.map((element) => (
                        <CustomProduct
                            media_base_url={media_base_url}
                            key={element.sid}
                            element={element}
                            selected={currentStepProduct === element.sid}
                            setProductSelected={setProductSelected}
                            eventDispatcher={eventDispatcher}
                        />
                    ))}
            </div>
        </>
    )
}

function SingleElement({ index, media_base_url, template, element, currentStepValue, steps, step, setCurrentStepValue, eventDispatcher }: {
    index: number,
    media_base_url: string,
    template: string,
    element: Element
    currentStepValue: string | null
    steps: Step[];
    step: Step;
    setCurrentStepValue: (stepValue: string, stepTitle: string, product?: string) => void
    eventDispatcher: typeof dispatchEvent
}) {
    switch (template) {
        case 'image-with-text-center':
            return (
                <CardImageCenterTitleAndDescCenter
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                />)
        case 'image-top-title-and-subtitle-left':
            return (
                <ImageTopTitleAndSubtitleLeft
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />)
        case 'image-top-title-and-subtitle-left-and-description-or-price-right':
            return (
                <ImageTopTitleAndSubtitleLeftAndDescriptionOrPriceRight
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />)
        case 'image-top-title-sx-left-and-description-or-price-right':
            return (
                <ImageTopTitleSxLeftAndDescriptionOrPriceRight
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />)
        case 'image-with-text-right':
            return (
                <ImageWithTextRight
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                />)
        //Questo è un caso speciale.. Mondoconvenienza non passa mai questo template.. Ma lo utilizzo io internamento per gestire le opzioni
        case 'option':
            return (
                <Option
                    index={index}
                    steps={steps}
                    media_base_url={media_base_url}
                    currentStepValue={currentStepValue}
                    step={step}
                    element={element}
                    setCurrentStepValue={setCurrentStepValue}
                    eventDispatcher={eventDispatcher}
                />)
        default:
            return <>Template {template} sconosciuto</>
    }
}