
import { useSearchParams } from "react-router-dom";
import { Step, StepConfig } from "../utils/models/config.model";
import { useMemo, useState } from "react";

export const useNavigatorUtil = (
  steps: Step[],
  currentStepSetup: StepConfig,
  setCurrentStepKey: (stepKey: string) => void
) => {
  const [params] = useSearchParams();
  // const [previousStep, setPreviousStep] = useState<Step | undefined>(undefined)
  const next = () => {
    let nextStepIndex = undefined
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].id === currentStepSetup.id) nextStepIndex = i + 1
    }
    if (nextStepIndex === undefined) throw new Error('Next step not founed')
    setCurrentStepKey(steps[nextStepIndex].id)
    // setPreviousStep(steps[nextStepIndex - 1])
  }
  const isFirst = steps[0].id === currentStepSetup.id
  const back = () => {
    let prevStepIndex = undefined
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].id === currentStepSetup.id) prevStepIndex = i - 1
    }
    if (prevStepIndex === undefined) throw new Error('Next step not founed')
    setCurrentStepKey(steps[prevStepIndex].id)
    // setPreviousStep(steps[prevStepIndex + 1])
  }
  let title = currentStepSetup?.title
  const regex = /<([a-zA-Z_0-9\-]+)>/g;
  let m;

  while ((m = regex.exec(title)) !== null) {
    title = title.replace(`<${m[1]}>`, `<strong>${params.get(m[1] + '_title')}</strong>`)
    break;
  }

  return useMemo(() => ({
    back,
    next,
    title,
    isFirst,
    // previousStep
  }), [back, next, title, isFirst/* , previousStep */])
}