import { useCallback, useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { init } from "../services/events";

export function useTagManagerInit() {
    const { userData, eventDispatcher } = useContext(AppContext)!;
    return useCallback(() => {
        init(eventDispatcher, userData);
    }, [userData, eventDispatcher]);
}

/**
 * @deprecated
 */
export function useTagManagerInitTrigger() {
    const init = useTagManagerInit();
    useEffect(() => {
        let ignore = false;
        if (!ignore) {
            init();
        }
        return () => {
            ignore = true;
        };
    }, []);
    return;
}
